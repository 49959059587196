import * as i0 from '@angular/core';
import { Injectable, EventEmitter, forwardRef, Component, ChangeDetectionStrategy, HostBinding, Input, Output, ViewChild, HostListener } from '@angular/core';
import * as i2$1 from '@angular/forms';
import { NG_VALUE_ACCESSOR, FormGroup, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { BsSharedPopupComponent, BsSharedIconComponent, AbstractBsSharedCommandComponent, BsSharedFormComponent, BsSharedAlertComponent, BsSharedResponseErrorControlComponent } from '@boekscout/bs-shared';
import { Subject, tap, startWith, filter, switchMap, BehaviorSubject, map, first, take } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import * as i3 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import * as i1 from '@boekscout/bs-core';
import { AbstractBsCoreRpcService } from '@boekscout/bs-core';
import * as i2 from '@angular/common/http';
const _c0 = ["formControlName", ""];
function BsStorageFileControlComponent_Conditional_5_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const extension_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", extension_r1, " ");
  }
}
function BsStorageFileControlComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1, " Deze extensie wordt niet ondersteund. Enkel de volgende extensies zijn toegestaan: ");
    i0.ɵɵelementStart(2, "ul");
    i0.ɵɵrepeaterCreate(3, BsStorageFileControlComponent_Conditional_5_For_4_Template, 2, 1, "li", null, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵrepeater(ctx_r1.extensions);
  }
}
function BsStorageFileControlComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 5);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.verb.uploading"));
  }
}
function BsStorageFileControlComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 6);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.preposition.pending"));
  }
}
const _c1 = [[["", 8, "form-action"]]];
const _c2 = [".form-action"];
const _c3 = () => ({});
const _c4 = a0 => ({
  properties: a0
});
function BsStorageFileDeleteComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "bs-shared-form", 1);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("formSubmitted", function BsStorageFileDeleteComponent_Conditional_0_Template_bs_shared_form_formSubmitted_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSubmit());
    });
    i0.ɵɵelementStart(3, "bs-shared-alert", 2);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 3)(7, "button", 4);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(10);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("response", i0.ɵɵpipeBind1(1, 5, ctx_r1.response$))("state", i0.ɵɵpipeBind1(2, 7, ctx_r1.state$))("formGroup", ctx_r1.form);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(5, 9, "bsStorage.file.delete.info"), " ");
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(9, 11, "shared.verb.delete"), " ");
  }
}
const _c5 = ["*"];
function BsStorageFileDownloadLinkComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function BsStorageFileDownloadLinkComponent_Conditional_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.download());
    });
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
const _c6 = ["fileInput"];
function BsStorageFileUploadComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "bs-shared-form", 2);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("formSubmitted", function BsStorageFileUploadComponent_Conditional_0_Template_bs_shared_form_formSubmitted_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSubmit());
    });
    i0.ɵɵelementStart(3, "div", 3)(4, "label");
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "input", 4, 0);
    i0.ɵɵlistener("change", function BsStorageFileUploadComponent_Conditional_0_Template_input_change_7_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onFileChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "div", 5)(10, "button", 6);
    i0.ɵɵpipe(11, "async");
    i0.ɵɵtext(12);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(14);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("response", i0.ɵɵpipeBind1(1, 6, ctx_r1.response$))("state", i0.ɵɵpipeBind1(2, 8, ctx_r1.state$))("formGroup", ctx_r1.form);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 10, "shared.noun.file"), " ");
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("disabled", !i0.ɵɵpipeBind1(11, 12, ctx_r1.hasFile$));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(13, 14, "shared.verb.upload"), " ");
  }
}
function BsStorageImageControlComponent_Conditional_4_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const extension_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", extension_r1, " ");
  }
}
function BsStorageImageControlComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1, " Deze extensie wordt niet ondersteund. Enkel de volgende extensies zijn toegestaan: ");
    i0.ɵɵelementStart(2, "ul");
    i0.ɵɵrepeaterCreate(3, BsStorageImageControlComponent_Conditional_4_For_4_Template, 2, 1, "li", null, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵrepeater(ctx_r1.extensions);
  }
}
function BsStorageImageControlComponent_Case_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 5);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.preposition.pending"));
  }
}
function BsStorageImageControlComponent_Case_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 6);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.verb.uploading"));
  }
}
function BsStorageImageControlComponent_Case_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 7);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.verb.uploaded"));
  }
}
function BsStorageImageControlComponent_Case_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-icon", 8);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 1, "shared.verb.failed"));
  }
}
function BsStorageImageDeleteComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "bs-shared-form", 1);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("formSubmitted", function BsStorageImageDeleteComponent_Conditional_0_Template_bs_shared_form_formSubmitted_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSubmit());
    });
    i0.ɵɵelementStart(3, "bs-shared-alert", 2);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 3)(7, "button", 4);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(10);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("response", i0.ɵɵpipeBind1(1, 5, ctx_r1.response$))("state", i0.ɵɵpipeBind1(2, 7, ctx_r1.state$))("formGroup", ctx_r1.form);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(5, 9, "bsStorage.image.delete.info"), " ");
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(9, 11, "shared.verb.delete"), " ");
  }
}
function BsStorageImageDownloadLinkComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function BsStorageImageDownloadLinkComponent_Conditional_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.download());
    });
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
const _c7 = ["svg"];
function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "rect", 13);
  }
  if (rf & 2) {
    const offset_r5 = i0.ɵɵnextContext();
    const newDimensions_r6 = i0.ɵɵnextContext();
    i0.ɵɵattribute("width", newDimensions_r6.width)("height", offset_r5.top);
  }
}
function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "rect", 13);
    i0.ɵɵelementStart(1, "rect", 14);
    i0.ɵɵlistener("mousedown", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template_rect_mousedown_1_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onStartOffset("left", $event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelement(2, "rect", 15);
    i0.ɵɵelementStart(3, "rect", 14);
    i0.ɵɵlistener("mousedown", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template_rect_mousedown_3_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onStartOffset("right", $event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Conditional_4_Template, 1, 2, ":svg:rect", 13);
    i0.ɵɵelementStart(5, "rect", 16);
    i0.ɵɵlistener("mousedown", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template_rect_mousedown_5_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onStartOffset("top", $event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelement(6, "rect", 17);
    i0.ɵɵelementStart(7, "rect", 16);
    i0.ɵɵlistener("mousedown", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template_rect_mousedown_7_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onStartOffset("bottom", $event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const offset_r5 = ctx;
    const newDimensions_r6 = i0.ɵɵnextContext();
    i0.ɵɵattribute("height", newDimensions_r6.height)("width", offset_r5.left);
    i0.ɵɵadvance();
    i0.ɵɵattribute("y", offset_r5.top + (newDimensions_r6.height - offset_r5.top - offset_r5.bottom) / 2)("x", offset_r5.left)("height", 24 / (600 / newDimensions_r6.width))("width", 12 / (600 / newDimensions_r6.width))("rx", 6 / (600 / newDimensions_r6.width));
    i0.ɵɵadvance();
    i0.ɵɵattribute("x", newDimensions_r6.width - offset_r5.right)("height", newDimensions_r6.height)("width", offset_r5.right);
    i0.ɵɵadvance();
    i0.ɵɵattribute("y", offset_r5.top + (newDimensions_r6.height - offset_r5.top - offset_r5.bottom) / 2)("x", newDimensions_r6.width - offset_r5.right - 10)("height", 24 / (600 / newDimensions_r6.width))("width", 12 / (600 / newDimensions_r6.width))("rx", 6 / (600 / newDimensions_r6.width));
    i0.ɵɵadvance();
    i0.ɵɵconditional(offset_r5.top ? 4 : -1);
    i0.ɵɵadvance();
    i0.ɵɵattribute("x", offset_r5.left + (newDimensions_r6.width - offset_r5.left - offset_r5.right) / 2)("y", offset_r5.top)("width", 24 / (600 / newDimensions_r6.width))("height", 12 / (600 / newDimensions_r6.width))("rx", 6 / (600 / newDimensions_r6.width));
    i0.ɵɵadvance();
    i0.ɵɵattribute("y", newDimensions_r6.height - offset_r5.bottom)("width", newDimensions_r6.width)("height", offset_r5.bottom);
    i0.ɵɵadvance();
    i0.ɵɵattribute("x", offset_r5.left + (newDimensions_r6.width - offset_r5.left - offset_r5.right) / 2)("y", newDimensions_r6.height - offset_r5.bottom - 10)("width", 24 / (600 / newDimensions_r6.width))("height", 12 / (600 / newDimensions_r6.width))("rx", 6 / (600 / newDimensions_r6.width));
  }
}
function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 11, 0);
    i0.ɵɵelement(3, "image", 12);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵtemplate(5, BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Conditional_5_Template, 8, 29);
    i0.ɵɵpipe(6, "async");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_13_0;
    const newDimensions_r6 = ctx;
    const fromPreviewLink_r7 = i0.ɵɵnextContext();
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("aspect-ratio", newDimensions_r6.width / newDimensions_r6.height);
    i0.ɵɵattribute("viewBox", "0 0 " + newDimensions_r6.width + " " + newDimensions_r6.height);
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("transform", i0.ɵɵpipeBind1(4, 8, ctx_r1.rotateTransform$))("height", ctx_r1.originalDimensions.height)("width", ctx_r1.originalDimensions.width)("href", fromPreviewLink_r7);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional((tmp_13_0 = i0.ɵɵpipeBind1(6, 10, ctx_r1.offset$)) ? 5 : -1, tmp_13_0);
  }
}
function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Conditional_1_Template, 7, 12, "div", 4);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementStart(3, "div", 5)(4, "button", 6);
    i0.ɵɵlistener("click", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.changeRotate(-90));
    });
    i0.ɵɵelement(5, "bs-shared-icon", 7);
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 6);
    i0.ɵɵlistener("click", function BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Template_button_click_7_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.changeRotate(90));
    });
    i0.ɵɵelement(8, "bs-shared-icon", 8);
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(10, "div", 9)(11, "button", 10);
    i0.ɵɵtext(12);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_4_0;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵconditional((tmp_4_0 = i0.ɵɵpipeBind1(2, 4, ctx_r1.newDimensions$)) ? 1 : -1, tmp_4_0);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(6, 6, "shared.verb.rotate"));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(9, 8, "shared.verb.rotate"));
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(13, 10, "shared.verb.make"), " ");
  }
}
function BsStorageImageMakeFromComponent_Conditional_0_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "bs-shared-alert");
    i0.ɵɵtext(1, " Afbeelding ontbreekt ");
    i0.ɵɵelementEnd();
  }
}
function BsStorageImageMakeFromComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "bs-shared-form", 2);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("formSubmitted", function BsStorageImageMakeFromComponent_Conditional_0_Template_bs_shared_form_formSubmitted_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSubmit());
    });
    i0.ɵɵtemplate(3, BsStorageImageMakeFromComponent_Conditional_0_Conditional_3_Template, 15, 12)(4, BsStorageImageMakeFromComponent_Conditional_0_Conditional_4_Template, 2, 0, "bs-shared-alert");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_5_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("response", i0.ɵɵpipeBind1(1, 4, ctx_r1.response$))("state", i0.ɵɵpipeBind1(2, 6, ctx_r1.state$))("formGroup", ctx_r1.form);
    i0.ɵɵadvance(3);
    i0.ɵɵconditional((tmp_5_0 = ctx_r1.from.meta.previewLink) ? 3 : 4, tmp_5_0);
  }
}
function BsStorageImageUploadComponent_Conditional_0_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "bs-shared-response-error-control", 4);
  }
  if (rf & 2) {
    i0.ɵɵproperty("error", ctx);
  }
}
function BsStorageImageUploadComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "bs-shared-form", 1);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("formSubmitted", function BsStorageImageUploadComponent_Conditional_0_Template_bs_shared_form_formSubmitted_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSubmit());
    });
    i0.ɵɵelementStart(3, "div", 2)(4, "label");
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "input", 3);
    i0.ɵɵlistener("change", function BsStorageImageUploadComponent_Conditional_0_Template_input_change_7_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onFileChange($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, BsStorageImageUploadComponent_Conditional_0_Conditional_8_Template, 1, 1, "bs-shared-response-error-control", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 5)(10, "button", 6);
    i0.ɵɵtext(11);
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(13);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_6_0;
    const errors_r3 = ctx;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("response", i0.ɵɵpipeBind1(1, 7, ctx_r1.response$))("state", i0.ɵɵpipeBind1(2, 9, ctx_r1.state$))("formGroup", ctx_r1.form);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 11, "shared.noun.image"), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵconditional((tmp_6_0 = errors_r3 == null ? null : errors_r3.properties == null ? null : errors_r3.properties.fileName) ? 8 : -1, tmp_6_0);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", ctx_r1.file === null);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(12, 13, "shared.verb.upload"), " ");
  }
}
class AbstractBsStorageRpcService extends AbstractBsCoreRpcService {
  getServerName() {
    return 'bs-storage';
  }
  getBuild() {
    return 'fb4a555b0dbcdadfece099bf8af34f99';
  }
}
class BsStorageFileService extends AbstractBsStorageRpcService {
  constructor() {
    super(...arguments);
    this.changed$ = new Subject();
  }
  create(host, name, reference, key) {
    return this.callServer('file.create', {
      host,
      name,
      reference,
      key
    }).pipe(tap(response => {
      this.changed$.next(response.id);
    }));
  }
  copy(from, host) {
    return this.callServer('file.copy', {
      from,
      host
    }).pipe(tap(response => {
      this.changed$.next(response.id);
    }));
  }
  delete(id) {
    return this.callServer('file.delete', {
      id
    }).pipe(tap(() => {
      this.changed$.next(id);
    }));
  }
  revise(id) {
    return this.callServer('file.revise', {
      id
    }).pipe(tap(() => {
      this.changed$.next(id);
    }));
  }
  getWithId(id) {
    return this.changed$.pipe(startWith(id), filter(input => input === id), switchMap(id => this.callServer('file.getWithId', {
      id
    })));
  }
  getWithHost(host) {
    return this.callServer('file.getWithHost', {
      host
    });
  }
  getWithHostKey(host, key) {
    return this.callServer('file.getWithHostKey', {
      host,
      key
    });
  }
  getDownloadUri(id, revision = null, force = null) {
    return this.callServer('file.getDownloadUri', {
      id,
      revision,
      force
    });
  }
  changes() {
    return this.changed$;
  }
  triggerChange(id) {
    this.changed$.next(id);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBsStorageFileService_BaseFactory;
      return function BsStorageFileService_Factory(__ngFactoryType__) {
        return (ɵBsStorageFileService_BaseFactory || (ɵBsStorageFileService_BaseFactory = i0.ɵɵgetInheritedFactory(BsStorageFileService)))(__ngFactoryType__ || BsStorageFileService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsStorageFileService,
      factory: BsStorageFileService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageFileService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class BsStorageFileControlComponent {
  constructor(bsStorageFileService, httpClient) {
    this.bsStorageFileService = bsStorageFileService;
    this.httpClient = httpClient;
    this.class = 'input-wrapper';
    this.host = null;
    this.extensions = null;
    this.key = null;
    this.onUploadStarted = new EventEmitter();
    this.onUploadFinished = new EventEmitter();
    this.uploading$ = new BehaviorSubject(false);
    this.disabled$ = new BehaviorSubject(false);
    this.extensionSupported$ = new BehaviorSubject(null);
    this.bsStorageFileService = bsStorageFileService;
  }
  onFileChange(event) {
    if (event.target.files.length === 1) {
      for (const file of event.target.files) {
        const extension = file.name.split('.').pop().toLowerCase();
        if (this.extensions === null || this.extensions.includes(extension)) {
          this.extensionSupported$.next(true);
          this.bsStorageFileService.create(this.host, file.name, null, this.key).pipe(tap(() => {
            this.uploading$.next(true);
            this.onUploadStarted.emit();
          }), switchMap(response => {
            return this.httpClient.put(response.uri, new File([file], file.name)).pipe(map(() => response));
          }), first()).subscribe(response => {
            this.uploading$.next(false);
            this.onUploadFinished.emit();
            this.onChange({
              type: 'storage.file',
              id: response.id
            });
          });
        } else {
          this.extensionSupported$.next(false);
        }
      }
    } else if (event.target.files.length > 1) {
      throw new Error('Cannot handle multiple files');
    } else {
      this.extensionSupported$.next(null);
      this.onChange(null);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled$.next(isDisabled);
  }
  writeValue(obj) {}
  static {
    this.ɵfac = function BsStorageFileControlComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageFileControlComponent)(i0.ɵɵdirectiveInject(BsStorageFileService), i0.ɵɵdirectiveInject(i2.HttpClient));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageFileControlComponent,
      selectors: [["bs-storage-file-control", "formControlName", ""], ["bs-storage-file-control", "formControl", ""], ["bs-storage-file-control", "ngModel", ""], [""]],
      hostVars: 2,
      hostBindings: function BsStorageFileControlComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
        }
      },
      inputs: {
        host: "host",
        extensions: "extensions",
        key: "key"
      },
      outputs: {
        onUploadStarted: "onUploadStarted",
        onUploadFinished: "onUploadFinished"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BsStorageFileControlComponent),
        multi: true
      }])],
      attrs: _c0,
      decls: 11,
      vars: 11,
      consts: [[1, "input-line"], [1, "input-wrapper"], ["type", "file", 3, "change", "disabled"], [1, "popup"], [1, "input-line-text"], ["name", "processing", 1, "spinning", 3, "title"], ["name", "upload", 3, "title"]],
      template: function BsStorageFileControlComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "bs-shared-popup", 1)(2, "input", 2);
          i0.ɵɵpipe(3, "async");
          i0.ɵɵpipe(4, "async");
          i0.ɵɵlistener("change", function BsStorageFileControlComponent_Template_input_change_2_listener($event) {
            return ctx.onFileChange($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, BsStorageFileControlComponent_Conditional_5_Template, 5, 0, "div", 3);
          i0.ɵɵpipe(6, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "span", 4);
          i0.ɵɵtemplate(8, BsStorageFileControlComponent_Conditional_8_Template, 2, 3, "bs-shared-icon", 5);
          i0.ɵɵpipe(9, "async");
          i0.ɵɵtemplate(10, BsStorageFileControlComponent_Conditional_10_Template, 2, 3, "bs-shared-icon", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("disabled", i0.ɵɵpipeBind1(3, 3, ctx.disabled$) || i0.ɵɵpipeBind1(4, 5, ctx.uploading$));
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(i0.ɵɵpipeBind1(6, 7, ctx.extensionSupported$) === false ? 5 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(i0.ɵɵpipeBind1(9, 9, ctx.uploading$) ? 8 : 10);
        }
      },
      dependencies: [BsSharedPopupComponent, AsyncPipe, BsSharedIconComponent, TranslateModule, i3.TranslatePipe],
      styles: ["@layer element{[_nghost-%COMP%]{display:block}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageFileControlComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: `
        [formControlName] bs-storage-file-control,
        [formControl] bs-storage-file-control,
        [ngModel] bs-storage-file-control,
    `,
      imports: [BsSharedPopupComponent, AsyncPipe, BsSharedIconComponent, TranslateModule],
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BsStorageFileControlComponent),
        multi: true
      }],
      template: `
        <div class="input-line">
            <bs-shared-popup class="input-wrapper">
                <input
                        (change)="onFileChange($event)"
                        [disabled]="(disabled$ | async) || (uploading$ | async)"
                        type="file"
                />
                @if ((extensionSupported$ | async) === false) {
                    <div class="popup">
                        Deze extensie wordt niet ondersteund. Enkel de volgende extensies zijn toegestaan:
                        <ul>
                            @for (extension of extensions; track extension) {
                                <li>
                                    {{ extension }}
                                </li>
                            }
                        </ul>
                    </div>
                }
            </bs-shared-popup>
            <span class="input-line-text">
                @if (uploading$ | async) {
                    <bs-shared-icon
                            [title]="'shared.verb.uploading' | translate"
                            name="processing"
                            class="spinning"
                    />
                } @else {
                    <bs-shared-icon
                            [title]="'shared.preposition.pending' | translate"
                            name="upload"
                    />
                }
            </span>
        </div>
    `,
      styles: ["@layer element{:host{display:block}}\n"]
    }]
  }], () => [{
    type: BsStorageFileService
  }, {
    type: i2.HttpClient
  }], {
    class: [{
      type: HostBinding,
      args: ['class']
    }],
    host: [{
      type: Input
    }],
    extensions: [{
      type: Input
    }],
    key: [{
      type: Input
    }],
    onUploadStarted: [{
      type: Output
    }],
    onUploadFinished: [{
      type: Output
    }]
  });
})();
class BsStorageFileDeleteComponent extends AbstractBsSharedCommandComponent {
  constructor(bsStorageFileService) {
    super();
    this.bsStorageFileService = bsStorageFileService;
    this.handled = new EventEmitter();
  }
  composeForm() {
    return new FormGroup({});
  }
  handle() {
    return this.bsStorageFileService.delete(this.id).pipe(tap(() => {
      this.handled.emit({
        id: this.id
      });
    }));
  }
  static {
    this.ɵfac = function BsStorageFileDeleteComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageFileDeleteComponent)(i0.ɵɵdirectiveInject(BsStorageFileService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageFileDeleteComponent,
      selectors: [["bs-storage-file-delete"]],
      inputs: {
        id: "id"
      },
      outputs: {
        handled: "handled"
      },
      features: [i0.ɵɵProvidersFeature([]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 2,
      vars: 6,
      consts: [[1, "gutter-vertical-gap", "form-alert", 3, "response", "state", "formGroup"], [1, "gutter-vertical-gap", "form-alert", 3, "formSubmitted", "response", "state", "formGroup"], [1, "tone-info"], [1, "form-actions"], ["type", "submit"]],
      template: function BsStorageFileDeleteComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BsStorageFileDeleteComponent_Conditional_0_Template, 11, 13, "bs-shared-form", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.errors$) || i0.ɵɵpureFunction1(4, _c4, i0.ɵɵpureFunction0(3, _c3))) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [BsSharedFormComponent, ReactiveFormsModule, i2$1.NgControlStatusGroup, i2$1.FormGroupDirective, AsyncPipe, TranslateModule, i3.TranslatePipe, BsSharedAlertComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageFileDeleteComponent, [{
    type: Component,
    args: [{
      selector: 'bs-storage-file-delete',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [BsSharedFormComponent, ReactiveFormsModule, AsyncPipe, TranslateModule, BsSharedAlertComponent],
      providers: [],
      template: `
        @if ((errors$ | async) || { properties: {} }; as errors) {
            <bs-shared-form
                    class="gutter-vertical-gap form-alert"
                    [response]="response$ | async"
                    (formSubmitted)="onSubmit()"
                    [state]="state$ | async"
                    [formGroup]="form"
            >
                <bs-shared-alert class="tone-info">
                    {{ 'bsStorage.file.delete.info' | translate }}
                </bs-shared-alert>
                <div class="form-actions">
                    <button type="submit">
                        {{ 'shared.verb.delete' | translate }}
                    </button>
                    <ng-content select=".form-action"/>
                </div>
            </bs-shared-form>
        }
    `
    }]
  }], () => [{
    type: BsStorageFileService
  }], {
    handled: [{
      type: Output
    }],
    id: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class BsStorageFileDownloadLinkComponent {
  constructor(featureService, fileService) {
    this.featureService = featureService;
    this.fileService = fileService;
    this.handled = new EventEmitter();
    this.sudo = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.subscription = this.featureService.check('sudo').subscribe(active => {
      this.sudo = active;
    });
  }
  download() {
    this.fileService.getDownloadUri(this.file.id, this.revision, this.sudo).pipe(take(1)).subscribe({
      next: response => {
        globalThis.open(response.result);
        this.handled.emit();
      },
      error: response => {
        globalThis.alert(response.data.message ?? response.message ?? response.code);
      }
    });
  }
  static {
    this.ɵfac = function BsStorageFileDownloadLinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageFileDownloadLinkComponent)(i0.ɵɵdirectiveInject(i1.BsCoreFeatureService), i0.ɵɵdirectiveInject(BsStorageFileService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageFileDownloadLinkComponent,
      selectors: [["bs-storage-file-download-link"]],
      inputs: {
        file: "file",
        revision: "revision"
      },
      outputs: {
        handled: "handled"
      },
      ngContentSelectors: _c5,
      decls: 1,
      vars: 1,
      consts: [["type", "button", 1, "link"], ["type", "button", 1, "link", 3, "click"]],
      template: function BsStorageFileDownloadLinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, BsStorageFileDownloadLinkComponent_Conditional_0_Template, 2, 0, "button", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.file.type === "storage.file" ? 0 : -1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageFileDownloadLinkComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'bs-storage-file-download-link',
      imports: [],
      template: `
        @if (file.type === 'storage.file') {
            <button
                    (click)="download()"
                    type="button"
                    class="link"
            >
                <ng-content></ng-content>
            </button>
        }
    `
    }]
  }], () => [{
    type: i1.BsCoreFeatureService
  }, {
    type: BsStorageFileService
  }], {
    handled: [{
      type: Output
    }],
    file: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    revision: [{
      type: Input
    }]
  });
})();
class BsStorageFileUploadComponent extends AbstractBsSharedCommandComponent {
  constructor(bsStorageFileService, httpClient) {
    super();
    this.bsStorageFileService = bsStorageFileService;
    this.httpClient = httpClient;
    this.handled = new EventEmitter();
    this.key = null;
    this.hasFile$ = new BehaviorSubject(false);
    this.bsStorageFileService = bsStorageFileService;
  }
  onFileChange(event) {
    this.hasFile$.next(event.target.files.length === 1);
  }
  composeForm() {
    return new UntypedFormGroup({});
  }
  handle() {
    const file = this.fileInput.nativeElement.files[0];
    return this.bsStorageFileService.create(this.host, file.name, null, this.key).pipe(switchMap(response => {
      return this.httpClient.put(response.uri, new File([file], file.name)).pipe(map(() => response));
    }), tap(response => this.handled.emit(response)));
  }
  static {
    this.ɵfac = function BsStorageFileUploadComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageFileUploadComponent)(i0.ɵɵdirectiveInject(BsStorageFileService), i0.ɵɵdirectiveInject(i2.HttpClient));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageFileUploadComponent,
      selectors: [["bs-storage-file-upload"]],
      viewQuery: function BsStorageFileUploadComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c6, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInput = _t.first);
        }
      },
      inputs: {
        host: "host",
        key: "key"
      },
      outputs: {
        handled: "handled"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 2,
      vars: 4,
      consts: [["fileInput", ""], [1, "gutter-vertical-gap", 3, "response", "state", "formGroup"], [1, "gutter-vertical-gap", 3, "formSubmitted", "response", "state", "formGroup"], [1, "form-part"], ["type", "file", 3, "change"], [1, "form-actions"], ["type", "submit", 3, "disabled"]],
      template: function BsStorageFileUploadComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BsStorageFileUploadComponent_Conditional_0_Template, 15, 16, "bs-shared-form", 1);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.errors$) || i0.ɵɵpureFunction0(3, _c3)) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [BsSharedFormComponent, AsyncPipe, TranslateModule, i3.TranslatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageFileUploadComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'bs-storage-file-upload',
      imports: [BsSharedFormComponent, AsyncPipe, TranslateModule],
      template: `
        @if ((errors$ | async) || {}; as errors) {
            <bs-shared-form
                    [response]="response$ | async"
                    (formSubmitted)="onSubmit()"
                    class="gutter-vertical-gap"
                    [state]="state$ | async"
                    [formGroup]="form"
            >
                <div class="form-part">
                    <label>
                        {{ 'shared.noun.file' | translate }}
                    </label>
                    <input
                            (change)="onFileChange($event)"
                            type="file"
                            #fileInput
                    />
                </div>
                <div class="form-actions">
                    <button type="submit" [disabled]="!(hasFile$ | async)">
                        {{ 'shared.verb.upload' | translate }}
                    </button>
                    <ng-content select=".form-action">
                    </ng-content>
                </div>
            </bs-shared-form>
        }
    `
    }]
  }], () => [{
    type: BsStorageFileService
  }, {
    type: i2.HttpClient
  }], {
    fileInput: [{
      type: ViewChild,
      args: ['fileInput']
    }],
    handled: [{
      type: Output
    }],
    host: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    key: [{
      type: Input
    }]
  });
})();
class BsStorageImageService extends AbstractBsStorageRpcService {
  constructor() {
    super(...arguments);
    this.changed$ = new Subject();
  }
  start(fileName) {
    return this.callServer('image.start', {
      fileName
    }).pipe(tap(response => {
      this.changed$.next(response.id);
    }));
  }
  makeFrom(from, rotate, offset) {
    return this.callServer('image.makeFrom', {
      from,
      rotate,
      offset
    }).pipe(tap(response => {
      this.changed$.next(response.id);
    }));
  }
  delete(id) {
    return this.callServer('image.delete', {
      id
    }).pipe(tap(() => {
      this.changed$.next(id);
    }));
  }
  getWithId(id) {
    return this.changed$.pipe(startWith(id), filter(input => input === id), switchMap(id => this.callServer('image.getWithId', {
      id
    })));
  }
  getWithStarter(starter, paginate) {
    return this.callServer('image.getWithStarter', {
      starter,
      paginate
    });
  }
  getByUploaded(paginate) {
    return this.callServer('image.getByUploaded', {
      paginate
    });
  }
  getDownloadUri(id) {
    return this.callServer('image.getDownloadUri', {
      id
    });
  }
  changes() {
    return this.changed$;
  }
  triggerChange(id) {
    this.changed$.next(id);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBsStorageImageService_BaseFactory;
      return function BsStorageImageService_Factory(__ngFactoryType__) {
        return (ɵBsStorageImageService_BaseFactory || (ɵBsStorageImageService_BaseFactory = i0.ɵɵgetInheritedFactory(BsStorageImageService)))(__ngFactoryType__ || BsStorageImageService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsStorageImageService,
      factory: BsStorageImageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class BsStorageImageControlComponent {
  constructor(bsStorageImageService, httpClient) {
    this.bsStorageImageService = bsStorageImageService;
    this.httpClient = httpClient;
    this.class = 'input-wrapper';
    this.onUploadStarted = new EventEmitter();
    this.onUploadFinished = new EventEmitter();
    this.extensionSupported$ = new BehaviorSubject(null);
    this.extensions = ['webp', 'png', 'jpeg', 'jpg'];
    this.state$ = new BehaviorSubject('default');
    this.disabled$ = new BehaviorSubject(false);
  }
  onFileChange(event) {
    if (event.target.files.length === 1) {
      for (const file of event.target.files) {
        const extension = file.name.split('.').pop().toLowerCase();
        if (this.extensions.includes(extension)) {
          this.extensionSupported$.next(true);
          this.bsStorageImageService.start(file.name).pipe(tap(_ => {
            this.state$.next('uploading');
            this.onUploadStarted.emit();
          }), switchMap(response => {
            return this.httpClient.put(response.upload, new File([file], file.name)).pipe(map(() => response));
          }), first()).subscribe({
            next: response => {
              this.state$.next('uploaded');
              this.onUploadFinished.emit();
              this.onChange({
                type: 'storage.image',
                id: response.id
              });
            },
            error: () => {
              this.state$.next('errored');
            }
          });
        } else {
          this.extensionSupported$.next(false);
        }
      }
    } else if (event.target.files.length > 1) {
      throw new Error('Cannot handle multiple files');
    } else {
      this.onChange(null);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled$.next(isDisabled);
  }
  writeValue(obj) {}
  static {
    this.ɵfac = function BsStorageImageControlComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageImageControlComponent)(i0.ɵɵdirectiveInject(BsStorageImageService), i0.ɵɵdirectiveInject(i2.HttpClient));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageImageControlComponent,
      selectors: [["bs-storage-image-control", "formControlName", ""], ["bs-storage-image-control", "formControl", ""], ["bs-storage-image-control", "ngModel", ""], [""]],
      hostVars: 2,
      hostBindings: function BsStorageImageControlComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
        }
      },
      outputs: {
        onUploadStarted: "onUploadStarted",
        onUploadFinished: "onUploadFinished"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BsStorageImageControlComponent),
        multi: true
      }])],
      attrs: _c0,
      decls: 12,
      vars: 9,
      consts: [[1, "input-line"], [1, "input-wrapper"], ["accept", "image/png,image/jpeg,image/jpg,image/webp", "type", "file", 3, "change", "disabled"], [1, "popup"], [1, "input-line-text"], ["name", "upload", 3, "title"], ["name", "processing", 1, "spinning", 3, "title"], ["name", "check", 1, "tone-success", 3, "title"], ["name", "warning", 1, "tone-warning", 3, "title"]],
      template: function BsStorageImageControlComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "bs-shared-popup", 1)(2, "input", 2);
          i0.ɵɵpipe(3, "async");
          i0.ɵɵlistener("change", function BsStorageImageControlComponent_Template_input_change_2_listener($event) {
            return ctx.onFileChange($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, BsStorageImageControlComponent_Conditional_4_Template, 5, 0, "div", 3);
          i0.ɵɵpipe(5, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "span", 4);
          i0.ɵɵtemplate(7, BsStorageImageControlComponent_Case_7_Template, 2, 3, "bs-shared-icon", 5);
          i0.ɵɵpipe(8, "async");
          i0.ɵɵtemplate(9, BsStorageImageControlComponent_Case_9_Template, 2, 3, "bs-shared-icon", 6)(10, BsStorageImageControlComponent_Case_10_Template, 2, 3, "bs-shared-icon", 7)(11, BsStorageImageControlComponent_Case_11_Template, 2, 3, "bs-shared-icon", 8);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_2_0;
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("disabled", i0.ɵɵpipeBind1(3, 3, ctx.disabled$));
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(i0.ɵɵpipeBind1(5, 5, ctx.extensionSupported$) === false ? 4 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional((tmp_2_0 = i0.ɵɵpipeBind1(8, 7, ctx.state$)) === "default" ? 7 : tmp_2_0 === "uploading" ? 9 : tmp_2_0 === "uploaded" ? 10 : tmp_2_0 === "errored" ? 11 : -1);
        }
      },
      dependencies: [BsSharedPopupComponent, BsSharedIconComponent, TranslateModule, i3.TranslatePipe, AsyncPipe],
      styles: ["@layer element{[_nghost-%COMP%]{display:block}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageControlComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: `
        [formControlName] bs-storage-image-control,
        [formControl] bs-storage-image-control,
        [ngModel] bs-storage-image-control,
    `,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BsStorageImageControlComponent),
        multi: true
      }],
      imports: [BsSharedPopupComponent, BsSharedIconComponent, TranslateModule, AsyncPipe],
      template: `
        <div class="input-line">
            <bs-shared-popup class="input-wrapper">
                <input
                        accept="image/png,image/jpeg,image/jpg,image/webp"
                        (change)="onFileChange($event)"
                        [disabled]="disabled$ | async"
                        type="file"
                />
                @if ((extensionSupported$ | async) === false) {
                    <div class="popup">
                        Deze extensie wordt niet ondersteund. Enkel de volgende extensies zijn toegestaan:
                        <ul>
                            @for (extension of extensions; track extension) {
                                <li>
                                    {{ extension }}
                                </li>
                            }
                        </ul>
                    </div>
                }
            </bs-shared-popup>
            <span class="input-line-text">
                @switch (state$ | async) {
                    @case ('default') {
                        <bs-shared-icon
                                [title]="'shared.preposition.pending' | translate"
                                name="upload"
                        />
                    }
                    @case ('uploading') {
                        <bs-shared-icon
                                [title]="'shared.verb.uploading' | translate"
                                name="processing"
                                class="spinning"
                        />
                    }
                    @case ('uploaded') {
                        <bs-shared-icon
                                [title]="'shared.verb.uploaded' | translate"
                                class="tone-success"
                                name="check"
                        />
                    }
                    @case ('errored') {
                        <bs-shared-icon
                                [title]="'shared.verb.failed' | translate"
                                class="tone-warning"
                                name="warning"
                        />
                    }
                }
            </span>
        </div>
    `,
      styles: ["@layer element{:host{display:block}}\n"]
    }]
  }], () => [{
    type: BsStorageImageService
  }, {
    type: i2.HttpClient
  }], {
    class: [{
      type: HostBinding,
      args: ['class']
    }],
    onUploadStarted: [{
      type: Output
    }],
    onUploadFinished: [{
      type: Output
    }]
  });
})();
class BsStorageImageDeleteComponent extends AbstractBsSharedCommandComponent {
  constructor(bsStorageImageService) {
    super();
    this.bsStorageImageService = bsStorageImageService;
    this.handled = new EventEmitter();
  }
  composeForm() {
    return new FormGroup({});
  }
  handle() {
    return this.bsStorageImageService.delete(this.id).pipe(tap(() => {
      this.handled.emit({
        id: this.id
      });
    }));
  }
  static {
    this.ɵfac = function BsStorageImageDeleteComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageImageDeleteComponent)(i0.ɵɵdirectiveInject(BsStorageImageService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageImageDeleteComponent,
      selectors: [["bs-storage-image-delete"]],
      inputs: {
        id: "id"
      },
      outputs: {
        handled: "handled"
      },
      features: [i0.ɵɵProvidersFeature([]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 2,
      vars: 6,
      consts: [[1, "gutter-vertical-gap", "form-alert", 3, "response", "state", "formGroup"], [1, "gutter-vertical-gap", "form-alert", 3, "formSubmitted", "response", "state", "formGroup"], [1, "tone-info"], [1, "form-actions"], ["type", "submit"]],
      template: function BsStorageImageDeleteComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BsStorageImageDeleteComponent_Conditional_0_Template, 11, 13, "bs-shared-form", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.errors$) || i0.ɵɵpureFunction1(4, _c4, i0.ɵɵpureFunction0(3, _c3))) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [BsSharedFormComponent, ReactiveFormsModule, i2$1.NgControlStatusGroup, i2$1.FormGroupDirective, AsyncPipe, TranslateModule, i3.TranslatePipe, BsSharedAlertComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageDeleteComponent, [{
    type: Component,
    args: [{
      selector: 'bs-storage-image-delete',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [BsSharedFormComponent, ReactiveFormsModule, AsyncPipe, TranslateModule, BsSharedAlertComponent],
      providers: [],
      template: `
        @if ((errors$ | async) || { properties: {} }; as errors) {
            <bs-shared-form
                    class="gutter-vertical-gap form-alert"
                    [response]="response$ | async"
                    (formSubmitted)="onSubmit()"
                    [state]="state$ | async"
                    [formGroup]="form"
            >
                <bs-shared-alert class="tone-info">
                    {{ 'bsStorage.image.delete.info' | translate }}
                </bs-shared-alert>
                <div class="form-actions">
                    <button type="submit">
                        {{ 'shared.verb.delete' | translate }}
                    </button>
                    <ng-content select=".form-action"/>
                </div>
            </bs-shared-form>
        }
    `
    }]
  }], () => [{
    type: BsStorageImageService
  }], {
    handled: [{
      type: Output
    }],
    id: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class BsStorageImageDownloadLinkComponent {
  constructor(bsStorageImageService) {
    this.bsStorageImageService = bsStorageImageService;
    this.handled = new EventEmitter();
  }
  download() {
    this.bsStorageImageService.getDownloadUri(this.image.id).pipe(take(1)).subscribe(response => {
      window.open(response.result);
      this.handled.emit();
    });
  }
  static {
    this.ɵfac = function BsStorageImageDownloadLinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageImageDownloadLinkComponent)(i0.ɵɵdirectiveInject(BsStorageImageService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageImageDownloadLinkComponent,
      selectors: [["bs-storage-image-download-link"]],
      inputs: {
        image: "image"
      },
      outputs: {
        handled: "handled"
      },
      ngContentSelectors: _c5,
      decls: 1,
      vars: 1,
      consts: [["type", "button", 1, "link"], ["type", "button", 1, "link", 3, "click"]],
      template: function BsStorageImageDownloadLinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, BsStorageImageDownloadLinkComponent_Conditional_0_Template, 2, 0, "button", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.image.type === "storage.image" ? 0 : -1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageDownloadLinkComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'bs-storage-image-download-link',
      imports: [],
      template: `
        @if (image.type === 'storage.image') {
            <button
                    (click)="download()"
                    type="button"
                    class="link"
            >
                <ng-content></ng-content>
            </button>            
        }
    `
    }]
  }], () => [{
    type: BsStorageImageService
  }], {
    handled: [{
      type: Output
    }],
    image: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class BsStorageImageMakeFromComponent extends AbstractBsSharedCommandComponent {
  mouseUp() {
    this.mouseMoveListener = null;
  }
  mouseMove(moveEvent) {
    if (this.mouseMoveListener) {
      this.mouseMoveListener(moveEvent);
    }
  }
  constructor(bsStorageImageService) {
    super();
    this.bsStorageImageService = bsStorageImageService;
    this.handled = new EventEmitter();
    this.mouseMoveListener = null;
    this.rotate$ = new BehaviorSubject(0);
    this.offset$ = new BehaviorSubject({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    });
  }
  ngOnInit() {
    super.ngOnInit();
    const info = this.from.meta.info;
    if (info === null) {
      throw new Error('Info on from required');
    }
    this.originalDimensions = {
      ...info.dimensions
    };
    this.newDimensions$ = this.rotate$.pipe(map(rotate => {
      if (rotate % 180 === 0) {
        return info.dimensions;
      }
      return {
        height: info.dimensions.width,
        width: info.dimensions.height
      };
    }));
    this.rotateTransform$ = this.rotate$.pipe(map(rotation => {
      switch (rotation) {
        case 0:
          return '0';
        case 90:
          {
            const offset = Math.floor(info.dimensions.height / 2);
            return `rotate(90, ${offset}, ${offset})`;
          }
        case 180:
          {
            const xOffset = Math.floor(info.dimensions.width / 2);
            const yOffset = Math.floor(info.dimensions.height / 2);
            return `rotate(180, ${xOffset}, ${yOffset})`;
          }
        case 270:
          {
            const offset = Math.floor(info.dimensions.width / 2);
            return `rotate(270, ${offset}, ${offset})`;
          }
        default:
          throw new Error(`Unexpected rotation '${rotation}'`);
      }
    }));
  }
  changeRotate(amount) {
    let rotate = (this.rotate$.getValue() + amount) % 360;
    while (rotate < 0) {
      rotate = 360 + rotate;
    }
    this.rotate$.next(rotate);
    this.offset$.next({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    });
  }
  composeForm() {
    return new FormGroup({});
  }
  handle() {
    return this.bsStorageImageService.makeFrom({
      type: this.from.type,
      id: this.from.id
    }, this.rotate$.getValue(), this.offset$.getValue()).pipe(tap(response => {
      this.handled.emit(response);
    }));
  }
  onStartOffset(type, startEvent) {
    const originalOffset = this.offset$.getValue();
    const height = this.svg.nativeElement.viewBox.baseVal.height;
    const width = this.svg.nativeElement.viewBox.baseVal.width;
    const pixelToPoint = height / this.svg.nativeElement.clientHeight;
    this.mouseMoveListener = mouseMoveEvent => {
      const xMoved = Math.floor((mouseMoveEvent.clientX - startEvent.clientX) * pixelToPoint);
      const yMoved = Math.floor((mouseMoveEvent.clientY - startEvent.clientY) * pixelToPoint);
      const newOffset = {
        ...this.offset$.getValue()
      };
      switch (type) {
        case 'left':
          newOffset.left = Math.min(Math.max(0, originalOffset.left + xMoved), width);
          break;
        case 'right':
          newOffset.right = Math.min(Math.max(0, originalOffset.right - xMoved), width);
          break;
        case 'top':
          newOffset.top = Math.min(Math.max(0, originalOffset.top + yMoved), height);
          break;
        case 'bottom':
          newOffset.bottom = Math.min(Math.max(0, originalOffset.bottom - yMoved), height);
          break;
      }
      this.offset$.next(newOffset);
      mouseMoveEvent.stopPropagation();
      mouseMoveEvent.preventDefault();
    };
  }
  static {
    this.ɵfac = function BsStorageImageMakeFromComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageImageMakeFromComponent)(i0.ɵɵdirectiveInject(BsStorageImageService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageImageMakeFromComponent,
      selectors: [["bs-storage-image-make-from"]],
      viewQuery: function BsStorageImageMakeFromComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c7, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.svg = _t.first);
        }
      },
      hostBindings: function BsStorageImageMakeFromComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseup", function BsStorageImageMakeFromComponent_mouseup_HostBindingHandler($event) {
            return ctx.mouseUp($event);
          }, false, i0.ɵɵresolveWindow)("mousemove", function BsStorageImageMakeFromComponent_mousemove_HostBindingHandler($event) {
            return ctx.mouseMove($event);
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        from: "from"
      },
      outputs: {
        handled: "handled"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 2,
      vars: 6,
      consts: [["svg", ""], [1, "gutter-vertical-gap", "form-input", 3, "response", "state", "formGroup"], [1, "gutter-vertical-gap", "form-input", 3, "formSubmitted", "response", "state", "formGroup"], [1, "img-editor"], [1, "image"], [1, "controls"], ["type", "button", 1, "link", 3, "click"], ["name", "rotate-left", 3, "title"], ["name", "rotate-right", 3, "title"], [1, "form-actions"], ["type", "submit"], ["xmlns", "http://www.w3.org/2000/svg"], ["x", "0", "y", "0"], ["fill-opacity", ".8", "fill", "#7A7A7ACC", "x", "0", "y", "0"], ["fill", "var(--tone-secondary-highlight-bg)", 1, "sizer-x", 3, "mousedown"], ["fill-opacity", ".8", "fill", "#7A7A7ACC", "y", "0"], ["fill", "var(--tone-secondary-highlight-bg)", 1, "sizer-y", 3, "mousedown"], ["fill-opacity", ".8", "fill", "#7A7A7ACC", "x", "0"]],
      template: function BsStorageImageMakeFromComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BsStorageImageMakeFromComponent_Conditional_0_Template, 5, 8, "bs-shared-form", 1);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.errors$) || i0.ɵɵpureFunction1(4, _c4, i0.ɵɵpureFunction0(3, _c3))) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [AsyncPipe, BsSharedFormComponent, BsSharedAlertComponent, BsSharedIconComponent, TranslateModule, i3.TranslatePipe],
      styles: [".img-editor[_ngcontent-%COMP%]{flex-direction:column;gap:var(--gutter);display:flex}.controls[_ngcontent-%COMP%]{justify-content:center;gap:var(--gutter);display:flex}.sizer-x[_ngcontent-%COMP%]{cursor:ew-resize}.sizer-y[_ngcontent-%COMP%]{cursor:ns-resize}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageMakeFromComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'bs-storage-image-make-from',
      imports: [AsyncPipe, BsSharedFormComponent, BsSharedAlertComponent, BsSharedIconComponent, TranslateModule],
      template: `
        @if ((errors$ | async) || { properties: {} }; as errors) {
            <bs-shared-form
                    class="gutter-vertical-gap form-input"
                    [response]="response$ | async"
                    (formSubmitted)="onSubmit()"
                    [state]="state$ | async"
                    [formGroup]="form"
            >
                @if (from.meta.previewLink; as fromPreviewLink) {
                    <div class="img-editor">
                        @if (newDimensions$ | async; as newDimensions) {
                            <div class="image">
                                <svg
                                        [attr.viewBox]="'0 0 ' + newDimensions.width + ' ' + newDimensions.height"
                                        [style.aspect-ratio]="newDimensions.width / newDimensions.height"
                                        xmlns="http://www.w3.org/2000/svg"
                                        #svg
                                >
                                    <image
                                            [attr.transform]="rotateTransform$ | async"
                                            [attr.height]="originalDimensions.height"
                                            [attr.width]="originalDimensions.width"
                                            [attr.href]="fromPreviewLink"
                                            x="0"
                                            y="0"
                                    />
                                    @if (offset$ | async; as offset) {
                                        <rect
                                                [attr.height]="newDimensions.height"
                                                [attr.width]="offset.left"
                                                fill-opacity=".8"
                                                fill="#7A7A7ACC"
                                                x="0"
                                                y="0"
                                        />
                                        <rect
                                                [attr.y]="offset.top + ((newDimensions.height - offset.top - offset.bottom) / 2)"
                                                (mousedown)="onStartOffset('left', $event)"
                                                fill="var(--tone-secondary-highlight-bg)"
                                                [attr.x]="offset.left"
                                                class="sizer-x"
                                                [attr.height]="24 / (600 / newDimensions.width)"
                                                [attr.width]="12 / (600 / newDimensions.width)"
                                                [attr.rx]="6 / (600 / newDimensions.width)"
                                        />
                                        <rect
                                                [attr.x]="newDimensions.width - offset.right"
                                                [attr.height]="newDimensions.height"
                                                [attr.width]="offset.right"
                                                fill-opacity=".8"
                                                fill="#7A7A7ACC"
                                                y="0"
                                        />
                                        <rect
                                                [attr.y]="offset.top + ((newDimensions.height - offset.top - offset.bottom) / 2)"
                                                [attr.x]="newDimensions.width - offset.right - 10"
                                                (mousedown)="onStartOffset('right', $event)"
                                                fill="var(--tone-secondary-highlight-bg)"
                                                class="sizer-x"
                                                [attr.height]="24 / (600 / newDimensions.width)"
                                                [attr.width]="12 / (600 / newDimensions.width)"
                                                [attr.rx]="6 / (600 / newDimensions.width)"
                                        />
                                        @if (offset.top) {
                                            <rect
                                                    [attr.width]="newDimensions.width"
                                                    [attr.height]="offset.top"
                                                    fill-opacity=".8"
                                                    fill="#7A7A7ACC"
                                                    x="0"
                                                    y="0"
                                            />
                                        }
                                        <rect
                                                [attr.x]="offset.left + ((newDimensions.width - offset.left - offset.right) / 2)"
                                                fill="var(--tone-secondary-highlight-bg)"
                                                (mousedown)="onStartOffset('top', $event)"
                                                [attr.y]="offset.top"
                                                class="sizer-y"
                                                [attr.width]="24 / (600 / newDimensions.width)"
                                                [attr.height]="12 / (600 / newDimensions.width)"
                                                [attr.rx]="6 / (600 / newDimensions.width)"
                                        />
                                        <rect
                                                [attr.y]="newDimensions.height - offset.bottom"
                                                [attr.width]="newDimensions.width"
                                                [attr.height]="offset.bottom"
                                                fill-opacity=".8"
                                                fill="#7A7A7ACC"
                                                x="0"
                                        />
                                        <rect
                                                [attr.x]="offset.left + ((newDimensions.width - offset.left - offset.right) / 2)"
                                                [attr.y]="newDimensions.height - offset.bottom - 10"
                                                (mousedown)="onStartOffset('bottom', $event)"
                                                fill="var(--tone-secondary-highlight-bg)"
                                                class="sizer-y"
                                                [attr.width]="24 / (600 / newDimensions.width)"
                                                [attr.height]="12 / (600 / newDimensions.width)"
                                                [attr.rx]="6 / (600 / newDimensions.width)"
                                        />
                                    }
                                </svg>
                            </div>
                        }
                        <div class="controls">
                            <button
                                    (click)="changeRotate(-90)"
                                    type="button"
                                    class="link"
                            >
                                <bs-shared-icon
                                        [title]="'shared.verb.rotate' | translate"
                                        name="rotate-left"
                                />
                            </button>
                            <button
                                    (click)="changeRotate(90)"
                                    type="button"
                                    class="link"
                            >
                                <bs-shared-icon
                                        [title]="'shared.verb.rotate' | translate"
                                        name="rotate-right"
                                />
                            </button>
                        </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit">
                            {{ 'shared.verb.make' | translate }}
                        </button>
                        <ng-content select=".form-action"></ng-content>
                    </div>
                } @else {
                    <bs-shared-alert>
                        Afbeelding ontbreekt
                    </bs-shared-alert>
                }
            </bs-shared-form>
        }
    `,
      styles: [".img-editor{flex-direction:column;gap:var(--gutter);display:flex}.controls{justify-content:center;gap:var(--gutter);display:flex}.sizer-x{cursor:ew-resize}.sizer-y{cursor:ns-resize}\n"]
    }]
  }], () => [{
    type: BsStorageImageService
  }], {
    svg: [{
      type: ViewChild,
      args: ['svg']
    }],
    handled: [{
      type: Output
    }],
    from: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    mouseUp: [{
      type: HostListener,
      args: ['window:mouseup', ['$event']]
    }],
    mouseMove: [{
      type: HostListener,
      args: ['window:mousemove', ['$event']]
    }]
  });
})();
class BsStorageImageUploadComponent extends AbstractBsSharedCommandComponent {
  constructor(bsStorageImageService, httpClient) {
    super();
    this.bsStorageImageService = bsStorageImageService;
    this.httpClient = httpClient;
    this.handled = new EventEmitter();
    this.file = null;
  }
  onFileChange(event) {
    if (event.target.files.length === 1) {
      this.file = event.target.files[0];
    } else {
      this.file = null;
    }
  }
  composeForm() {
    return new FormGroup({});
  }
  handle() {
    const file = this.file;
    if (file === null) {
      throw new Error();
    }
    return this.bsStorageImageService.start(file.name).pipe(switchMap(response => {
      return this.httpClient.put(response.upload, new File([file], file.name)).pipe(map(() => response));
    }), tap(response => this.handled.emit(response)));
  }
  static {
    this.ɵfac = function BsStorageImageUploadComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsStorageImageUploadComponent)(i0.ɵɵdirectiveInject(BsStorageImageService), i0.ɵɵdirectiveInject(i2.HttpClient));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BsStorageImageUploadComponent,
      selectors: [["bs-storage-image-upload"]],
      outputs: {
        handled: "handled"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 2,
      vars: 6,
      consts: [[1, "gutter-vertical-gap", 3, "response", "state", "formGroup"], [1, "gutter-vertical-gap", 3, "formSubmitted", "response", "state", "formGroup"], [1, "form-part"], ["accept", "image/png,image/jpeg", "type", "file", 3, "change"], [3, "error"], [1, "form-actions"], ["type", "submit", 3, "disabled"]],
      template: function BsStorageImageUploadComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BsStorageImageUploadComponent_Conditional_0_Template, 14, 15, "bs-shared-form", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.errors$) || i0.ɵɵpureFunction1(4, _c4, i0.ɵɵpureFunction0(3, _c3))) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [BsSharedFormComponent, AsyncPipe, TranslateModule, i3.TranslatePipe, BsSharedResponseErrorControlComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsStorageImageUploadComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'bs-storage-image-upload',
      imports: [BsSharedFormComponent, AsyncPipe, TranslateModule, BsSharedResponseErrorControlComponent],
      template: `
        @if ((errors$ | async) || { properties: {} }; as errors) {
            <bs-shared-form
                    [response]="response$ | async"
                    (formSubmitted)="onSubmit()"
                    class="gutter-vertical-gap"
                    [state]="state$ | async"
                    [formGroup]="form"
            >
                <div class="form-part">
                    <label>
                        {{ 'shared.noun.image' | translate }}
                    </label>
                    <input
                            (change)="onFileChange($event)"
                            accept="image/png,image/jpeg"
                            type="file"
                    />
                    @if (errors?.properties?.fileName; as error) {
                        <bs-shared-response-error-control [error]="error"/>
                    }
                </div>
                <div class="form-actions">
                    <button
                            [disabled]="file === null"
                            type="submit"
                    >
                        {{ 'shared.verb.upload' | translate }}
                    </button>
                    <ng-content select=".form-action">
                    </ng-content>
                </div>
            </bs-shared-form>
        }
    `
    }]
  }], () => [{
    type: BsStorageImageService
  }, {
    type: i2.HttpClient
  }], {
    handled: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BsStorageFileControlComponent, BsStorageFileDeleteComponent, BsStorageFileDownloadLinkComponent, BsStorageFileService, BsStorageFileUploadComponent, BsStorageImageControlComponent, BsStorageImageDeleteComponent, BsStorageImageDownloadLinkComponent, BsStorageImageMakeFromComponent, BsStorageImageService, BsStorageImageUploadComponent };
